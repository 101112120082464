/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { extentContainsPoint as n, ringsContainsCoords as t, extentContainsCoords2D as e, extentContainsCoords3D as r } from "./contains.js";
function i(t, e) {
  return n(t, e);
}
function o(n, t) {
  const e = n.hasZ && t.hasZ;
  let r, i, o;
  if (n.xmin <= t.xmin) {
    if (r = t.xmin, n.xmax < r) return !1;
  } else if (r = n.xmin, t.xmax < r) return !1;
  if (n.ymin <= t.ymin) {
    if (i = t.ymin, n.ymax < i) return !1;
  } else if (i = n.ymin, t.ymax < i) return !1;
  if (e && t.hasZ) if (n.zmin <= t.zmin) {
    if (o = t.zmin, n.zmax < o) return !1;
  } else if (o = n.zmin, t.zmax < o) return !1;
  return !0;
}
function f(n, t) {
  const {
      points: i,
      hasZ: o
    } = t,
    f = o ? r : e;
  for (const e of i) if (f(n, e)) return !0;
  return !1;
}
const s = [0, 0],
  c = [0, 0],
  u = [0, 0],
  m = [0, 0],
  l = [s, c, u, m],
  a = [[u, s], [s, c], [c, m], [m, u]];
function x(n, t) {
  return y(n, t.rings);
}
function y(n, r) {
  s[0] = n.xmin, s[1] = n.ymax, c[0] = n.xmax, c[1] = n.ymax, u[0] = n.xmin, u[1] = n.ymin, m[0] = n.xmax, m[1] = n.ymin;
  for (const e of l) if (t(r, e)) return !0;
  for (const t of r) {
    if (!t.length) continue;
    let r = t[0];
    if (e(n, r)) return !0;
    for (let i = 1; i < t.length; i++) {
      const o = t[i];
      if (e(n, o) || z(r, o, a)) return !0;
      r = o;
    }
  }
  return !1;
}
function h(n, t) {
  s[0] = n.xmin, s[1] = n.ymax, c[0] = n.xmax, c[1] = n.ymax, u[0] = n.xmin, u[1] = n.ymin, m[0] = n.xmax, m[1] = n.ymin;
  const r = t.paths;
  for (const i of r) {
    if (!r.length) continue;
    let t = i[0];
    if (e(n, t)) return !0;
    for (let r = 1; r < i.length; r++) {
      const o = i[r];
      if (e(n, o) || z(t, o, a)) return !0;
      t = o;
    }
  }
  return !1;
}
const g = [0, 0];
function p(n) {
  for (let t = 0; t < n.length; t++) {
    const e = n[t];
    for (let i = 0; i < e.length - 1; i++) {
      const r = e[i],
        o = e[i + 1];
      for (let e = t + 1; e < n.length; e++) for (let t = 0; t < n[e].length - 1; t++) {
        const i = n[e][t],
          f = n[e][t + 1];
        if (G(r, o, i, f, g) && !(g[0] === r[0] && g[1] === r[1] || g[0] === i[0] && g[1] === i[1] || g[0] === o[0] && g[1] === o[1] || g[0] === f[0] && g[1] === f[1])) return !0;
      }
    }
    const r = e.length;
    if (!(r <= 4)) for (let n = 0; n < r - 3; n++) {
      let t = r - 1;
      0 === n && (t = r - 2);
      const i = e[n],
        o = e[n + 1];
      for (let r = n + 2; r < t; r++) {
        const n = e[r],
          t = e[r + 1];
        if (G(i, o, n, t, g) && !(g[0] === i[0] && g[1] === i[1] || g[0] === n[0] && g[1] === n[1] || g[0] === o[0] && g[1] === o[1] || g[0] === t[0] && g[1] === t[1])) return !0;
      }
    }
  }
  return !1;
}
function z(n, t, e) {
  for (let r = 0; r < e.length; r++) if (G(n, t, e[r][0], e[r][1])) return !0;
  return !1;
}
function G(n, t, e, r, i) {
  const [o, f] = n,
    [s, c] = t,
    [u, m] = e,
    [l, a] = r,
    x = l - u,
    y = o - u,
    h = s - o,
    g = a - m,
    p = f - m,
    z = c - f,
    G = g * h - x * z;
  if (0 === G) return !1;
  const P = (x * p - g * y) / G,
    Z = (h * p - z * y) / G;
  return P >= 0 && P <= 1 && Z >= 0 && Z <= 1 && (i && (i[0] = o + P * (s - o), i[1] = f + P * (c - f)), !0);
}
function P(n) {
  switch (n) {
    case "esriGeometryEnvelope":
    case "extent":
      return o;
    case "esriGeometryMultipoint":
    case "multipoint":
      return f;
    case "esriGeometryPoint":
    case "point":
      return i;
    case "esriGeometryMultiPatch":
    case "multipatch":
    case "esriGeometryPolygon":
    case "polygon":
      return x;
    case "esriGeometryPolyline":
    case "polyline":
      return h;
  }
}
export { o as extentIntersectsExtent, f as extentIntersectsMultipoint, i as extentIntersectsPoint, x as extentIntersectsPolygon, h as extentIntersectsPolyline, P as getFeatureExtentIntersector, p as isSelfIntersecting, G as segmentIntersects };