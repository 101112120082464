/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { replace as i } from "../core/string.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import n from "./Geometry.js";
import m from "./Point.js";
import r from "./SpatialReference.js";
import { extentContainsPoint as a, extentContainsExtent as h } from "./support/contains.js";
import { getFeatureExtentIntersector as o } from "./support/intersectsBase.js";
import { equals as x, getInfo as l } from "./support/spatialReferenceUtils.js";
import { canProject as c, geographicToWebMercator as p, webMercatorToGeographic as y } from "./support/webMercatorUtils.js";
var u;
function f(t) {
  return t && ("esri.geometry.SpatialReference" === t.declaredClass || null != t.wkid);
}
function d(t, i, e) {
  return null == i ? e : null == e ? i : t(i, e);
}
let z = u = class extends n {
  constructor(...t) {
    super(...t), this.type = "extent", this.xmin = 0, this.ymin = 0, this.mmin = void 0, this.zmin = void 0, this.xmax = 0, this.ymax = 0, this.mmax = void 0, this.zmax = void 0;
  }
  normalizeCtorArgs(t, i, e, s, n) {
    return f(t) ? {
      spatialReference: t,
      xmin: 0,
      ymin: 0,
      xmax: 0,
      ymax: 0
    } : "object" == typeof t ? (t.spatialReference = null == t.spatialReference ? r.WGS84 : t.spatialReference, t) : {
      xmin: t,
      ymin: i,
      xmax: e,
      ymax: s,
      spatialReference: n ?? r.WGS84
    };
  }
  static fromBounds(t, i) {
    return new u({
      xmin: t[0],
      ymin: t[1],
      xmax: t[2],
      ymax: t[3],
      spatialReference: i
    });
  }
  static fromPoint(t) {
    return new u({
      xmin: t.x,
      ymin: t.y,
      zmin: t.z,
      xmax: t.x,
      ymax: t.y,
      zmax: t.z,
      spatialReference: t.spatialReference
    });
  }
  get cache() {
    return this.commitProperty("xmin"), this.commitProperty("ymin"), this.commitProperty("zmin"), this.commitProperty("mmin"), this.commitProperty("xmax"), this.commitProperty("ymax"), this.commitProperty("zmax"), this.commitProperty("mmax"), this.commitProperty("spatialReference"), {};
  }
  get center() {
    const t = new m({
      x: .5 * (this.xmin + this.xmax),
      y: .5 * (this.ymin + this.ymax),
      spatialReference: this.spatialReference
    });
    return this.hasZ && (t.z = .5 * (this.zmin + this.zmax)), this.hasM && (t.m = .5 * (this.mmin + this.mmax)), t;
  }
  get extent() {
    return this.clone();
  }
  get hasM() {
    return null != this.mmin && null != this.mmax;
  }
  get hasZ() {
    return null != this.zmin && null != this.zmax;
  }
  get height() {
    return Math.abs(this.ymax - this.ymin);
  }
  get width() {
    return Math.abs(this.xmax - this.xmin);
  }
  centerAt(t) {
    const i = this.center;
    return null != t.z && this.hasZ ? this.offset(t.x - i.x, t.y - i.y, t.z - i.z) : this.offset(t.x - i.x, t.y - i.y);
  }
  clone() {
    const t = new u();
    return t.xmin = this.xmin, t.ymin = this.ymin, t.xmax = this.xmax, t.ymax = this.ymax, t.spatialReference = this.spatialReference, null != this.zmin && (t.zmin = this.zmin, t.zmax = this.zmax), null != this.mmin && (t.mmin = this.mmin, t.mmax = this.mmax), t;
  }
  contains(t) {
    if (!t) return !1;
    const i = this.spatialReference,
      e = t.spatialReference;
    return i && e && !i.equals(e) && c(i, e) && (t = i.isWebMercator ? p(t) : y(t, !0)), "point" === t.type ? a(this, t) : "extent" === t.type && h(this, t);
  }
  equals(t) {
    if (this === t) return !0;
    if (null == t) return !1;
    const i = this.spatialReference,
      e = t.spatialReference;
    return i && e && !i.equals(e) && c(i, e) && (t = i.isWebMercator ? p(t) : y(t, !0)), this.xmin === t.xmin && this.ymin === t.ymin && this.zmin === t.zmin && this.mmin === t.mmin && this.xmax === t.xmax && this.ymax === t.ymax && this.zmax === t.zmax && this.mmax === t.mmax;
  }
  expand(t) {
    const i = .5 * (1 - t),
      e = this.width * i,
      s = this.height * i;
    if (this.xmin += e, this.ymin += s, this.xmax -= e, this.ymax -= s, this.hasZ) {
      const t = (this.zmax - this.zmin) * i;
      this.zmin += t, this.zmax -= t;
    }
    if (this.hasM) {
      const t = (this.mmax - this.mmin) * i;
      this.mmin += t, this.mmax -= t;
    }
    return this;
  }
  intersects(t) {
    if (null == t) return !1;
    "mesh" === t.type && (t = t.extent);
    const i = this.spatialReference,
      e = t.spatialReference;
    i && e && !x(i, e) && c(i, e) && (t = i.isWebMercator ? p(t) : y(t, !0));
    return o(t.type)(this, t);
  }
  normalize() {
    const t = this._normalize(!1, !0);
    return Array.isArray(t) ? t : [t];
  }
  offset(t, i, e) {
    return this.xmin += t, this.ymin += i, this.xmax += t, this.ymax += i, null != e && (this.zmin += e, this.zmax += e), this;
  }
  shiftCentralMeridian() {
    return this._normalize(!0);
  }
  union(t) {
    return this === t || (this.xmin = Math.min(this.xmin, t.xmin), this.ymin = Math.min(this.ymin, t.ymin), this.xmax = Math.max(this.xmax, t.xmax), this.ymax = Math.max(this.ymax, t.ymax), (this.hasZ || t.hasZ) && (this.zmin = d(Math.min, this.zmin, t.zmin), this.zmax = d(Math.max, this.zmax, t.zmax)), (this.hasM || t.hasM) && (this.mmin = d(Math.min, this.mmin, t.mmin), this.mmax = d(Math.max, this.mmax, t.mmax))), this;
  }
  intersection(t) {
    return this === t ? this : null != t && this.intersects(t) ? (this.xmin = Math.max(this.xmin, t.xmin), this.ymin = Math.max(this.ymin, t.ymin), this.xmax = Math.min(this.xmax, t.xmax), this.ymax = Math.min(this.ymax, t.ymax), (this.hasZ || t.hasZ) && (this.zmin = d(Math.max, this.zmin, t.zmin), this.zmax = d(Math.min, this.zmax, t.zmax)), (this.hasM || t.hasM) && (this.mmin = d(Math.max, this.mmin, t.mmin), this.mmax = d(Math.min, this.mmax, t.mmax)), this) : null;
  }
  toJSON(t) {
    return this.write({}, t);
  }
  _shiftCM(t = l(this.spatialReference)) {
    if (!t || !this.spatialReference) return this;
    const e = this.spatialReference,
      s = this._getCM(t);
    if (s) {
      const n = e.isWebMercator ? y(s) : s;
      this.xmin -= s.x, this.xmax -= s.x, e.isWebMercator || (n.x = M(n.x, t).x), this.spatialReference = new r(i((e.isWGS84 ? t.altTemplate : null) ?? t.wkTemplate, {
        Central_Meridian: n.x
      }));
    }
    return this;
  }
  _getCM(t) {
    let i = null;
    const [e, s] = t.valid,
      n = this.xmin,
      m = this.xmax;
    return n >= e && n <= s && m >= e && m <= s || (i = this.center), i;
  }
  _normalize(t, i, e) {
    const s = this.spatialReference;
    if (!s) return this;
    const n = e ?? l(s);
    if (null == n) return this;
    const m = this._getParts(n).map(t => t.extent);
    if (m.length < 2) return m[0] || this;
    if (m.length > 2) return t ? this._shiftCM(n) : this.set({
      xmin: n.valid[0],
      xmax: n.valid[1]
    });
    if (t) return this._shiftCM(n);
    if (i) return m;
    let r = !0,
      a = !0;
    return m.forEach(t => {
      t.hasZ || (r = !1), t.hasM || (a = !1);
    }), {
      rings: m.map(t => {
        const i = [[t.xmin, t.ymin], [t.xmin, t.ymax], [t.xmax, t.ymax], [t.xmax, t.ymin], [t.xmin, t.ymin]];
        if (r) {
          const e = (t.zmax - t.zmin) / 2;
          for (let t = 0; t < i.length; t++) i[t].push(e);
        }
        if (a) {
          const e = (t.mmax - t.mmin) / 2;
          for (let t = 0; t < i.length; t++) i[t].push(e);
        }
        return i;
      }),
      hasZ: r,
      hasM: a,
      spatialReference: s
    };
  }
  _getParts(t) {
    let i = this.cache._parts;
    if (!i) {
      i = [];
      const {
          ymin: e,
          ymax: s,
          spatialReference: n
        } = this,
        m = this.width,
        r = this.xmin,
        a = this.xmax;
      let h;
      t = t || l(n);
      const [o, x] = t.valid;
      h = M(this.xmin, t);
      const c = h.x,
        p = h.frameId;
      h = M(this.xmax, t);
      const y = h.x,
        f = h.frameId,
        d = c === y && m > 0;
      if (m > 2 * x) {
        const t = new u(r < a ? c : y, e, x, s, n),
          m = new u(o, e, r < a ? y : c, s, n),
          h = new u(0, e, x, s, n),
          l = new u(o, e, 0, s, n),
          d = [],
          z = [];
        t.contains(h) && d.push(p), t.contains(l) && z.push(p), m.contains(h) && d.push(f), m.contains(l) && z.push(f);
        for (let i = p + 1; i < f; i++) d.push(i), z.push(i);
        i.push({
          extent: t,
          frameIds: [p]
        }, {
          extent: m,
          frameIds: [f]
        }, {
          extent: h,
          frameIds: d
        }, {
          extent: l,
          frameIds: z
        });
      } else c > y || d ? i.push({
        extent: new u(c, e, x, s, n),
        frameIds: [p]
      }, {
        extent: new u(o, e, y, s, n),
        frameIds: [f]
      }) : i.push({
        extent: new u(c, e, y, s, n),
        frameIds: [p]
      });
      this.cache._parts = i;
    }
    const e = this.hasZ,
      s = this.hasM;
    if (e || s) {
      const t = {};
      e && (t.zmin = this.zmin, t.zmax = this.zmax), s && (t.mmin = this.mmin, t.mmax = this.mmax);
      for (let e = 0; e < i.length; e++) i[e].extent.set(t);
    }
    return i;
  }
};
function M(t, i) {
  const [e, s] = i.valid,
    n = 2 * s;
  let m,
    r = 0;
  return t > s ? (m = Math.ceil(Math.abs(t - s) / n), t -= m * n, r = m) : t < e && (m = Math.ceil(Math.abs(t - e) / n), t += m * n, r = -m), {
    x: t,
    frameId: r
  };
}
t([e({
  readOnly: !0
})], z.prototype, "cache", null), t([e({
  readOnly: !0
})], z.prototype, "center", null), t([e({
  readOnly: !0
})], z.prototype, "extent", null), t([e({
  readOnly: !0,
  json: {
    write: {
      enabled: !1,
      overridePolicy: null
    }
  }
})], z.prototype, "hasM", null), t([e({
  readOnly: !0,
  json: {
    write: {
      enabled: !1,
      overridePolicy: null
    }
  }
})], z.prototype, "hasZ", null), t([e({
  readOnly: !0
})], z.prototype, "height", null), t([e({
  readOnly: !0
})], z.prototype, "width", null), t([e({
  type: Number,
  json: {
    type: [Number, String],
    write: {
      enabled: !0,
      allowNull: !0
    }
  }
})], z.prototype, "xmin", void 0), t([e({
  type: Number,
  json: {
    write: !0
  }
})], z.prototype, "ymin", void 0), t([e({
  type: Number,
  json: {
    origins: {
      "web-scene": {
        write: !1
      }
    },
    write: {
      overridePolicy() {
        return {
          enabled: this.hasM
        };
      }
    }
  }
})], z.prototype, "mmin", void 0), t([e({
  type: Number,
  json: {
    origins: {
      "web-scene": {
        write: !1
      }
    },
    write: {
      overridePolicy() {
        return {
          enabled: this.hasZ
        };
      }
    }
  }
})], z.prototype, "zmin", void 0), t([e({
  type: Number,
  json: {
    write: !0
  }
})], z.prototype, "xmax", void 0), t([e({
  type: Number,
  json: {
    write: !0
  }
})], z.prototype, "ymax", void 0), t([e({
  type: Number,
  json: {
    origins: {
      "web-scene": {
        write: !1
      }
    },
    write: {
      overridePolicy() {
        return {
          enabled: this.hasM
        };
      }
    }
  }
})], z.prototype, "mmax", void 0), t([e({
  type: Number,
  json: {
    origins: {
      "web-scene": {
        write: !1
      }
    },
    write: {
      overridePolicy() {
        return {
          enabled: this.hasZ
        };
      }
    }
  }
})], z.prototype, "zmax", void 0), z = u = t([s("esri.geometry.Extent")], z), z.prototype.toJSON.isDefaultToJSON = !0;
const w = z;
export { w as default };