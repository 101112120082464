/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { clone as n } from "../../core/lang.js";
import t from "../SpatialReference.js";
import { earth as e } from "./Ellipsoid.js";
import { equals as i, isWebMercator as r, isWGS84 as s, webMercatorValid as a } from "./spatialReferenceUtils.js";
const o = 57.29577951308232,
  l = .017453292519943;
function u(n) {
  return n * o;
}
function f(n) {
  return n * l;
}
function p(n) {
  return n / e.radius;
}
function c(n) {
  return Math.PI / 2 - 2 * Math.atan(Math.exp(-n / e.radius));
}
function h(n) {
  return null != n.wkid || null != n.wkt;
}
const m = [0, 0];
function x(n, t, e, i, r) {
  const s = n,
    a = r;
  if (a.spatialReference = e, "x" in s && "x" in a) [a.x, a.y] = t(s.x, s.y, m, i);else if ("xmin" in s && "xmin" in a) [a.xmin, a.ymin] = t(s.xmin, s.ymin, m, i), [a.xmax, a.ymax] = t(s.xmax, s.ymax, m, i);else if ("paths" in s && "paths" in a || "rings" in s && "rings" in a) {
    const n = "paths" in s ? s.paths : s.rings,
      e = [];
    let r;
    for (let s = 0; s < n.length; s++) {
      const a = n[s];
      r = [], e.push(r);
      for (let n = 0; n < a.length; n++) r.push(t(a[n][0], a[n][1], [0, 0], i)), a[n].length > 2 && r[n].push(a[n][2]), a[n].length > 3 && r[n].push(a[n][3]);
    }
    "paths" in a ? a.paths = e : a.rings = e;
  } else if ("points" in s && "points" in a) {
    const n = s.points,
      e = [];
    for (let r = 0; r < n.length; r++) e[r] = t(n[r][0], n[r][1], [0, 0], i), n[r].length > 2 && e[r].push(n[r][2]), n[r].length > 3 && e[r].push(n[r][3]);
    a.points = e;
  }
  return r;
}
function g(n, t) {
  const e = n && (h(n) ? n : n.spatialReference),
    a = t && (h(t) ? t : t.spatialReference);
  return !(n && "type" in n && "mesh" === n.type || t && "type" in t && "mesh" === t.type || !e || !a) && (!!i(a, e) || r(a) && s(e) || r(e) && s(a));
}
function M(e, a) {
  if (null == e) return null;
  const o = e.spatialReference,
    l = a && (h(a) ? a : a.spatialReference);
  return g(o, l) ? i(o, l) ? n(e) : r(l) ? x(e, y, t.WebMercator, !1, n(e)) : s(l) ? x(e, d, t.WGS84, !1, n(e)) : null : null;
}
function y(n, t, i = [0, 0]) {
  t > 89.99999 ? t = 89.99999 : t < -89.99999 && (t = -89.99999);
  const r = f(t);
  return i[0] = f(n) * e.radius, i[1] = e.halfSemiMajorAxis * Math.log((1 + Math.sin(r)) / (1 - Math.sin(r))), i;
}
function d(n, t, i = [0, 0], r = !1) {
  if (n === a[0]) i[0] = -180;else if (n === a[1]) i[0] = 180;else {
    const t = u(n / e.radius);
    i[0] = r ? t : t - 360 * Math.floor((t + 180) / 360);
  }
  return i[1] = u(Math.PI / 2 - 2 * Math.atan(Math.exp(-t / e.radius))), i;
}
function R(e, i = !1, r = n(e)) {
  return x(e, y, t.WebMercator, i, r);
}
function j(e, i = !1, r = n(e)) {
  return x(e, d, t.WGS84, i, r);
}
export { g as canProject, R as geographicToWebMercator, y as lngLatToXY, M as project, j as webMercatorToGeographic, p as x2lon, d as xyToLngLat, c as y2lat };